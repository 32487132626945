/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@600;800&display=swap');

* {
  touch-action: pan-y;
  text-size-adjust: none;
}

body {
  font-size: 32px;
  color: white;
  background-color: #121213;
  font-family: 'Mulish', sans-serif;
  user-select: none;
}

.full-ad-parent{
  zoom: var(--dpr, 1);
  scale: calc(1/var(--dpr, 1));
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-dialog-container {
  background: #121213 !important;
  border: 1px solid grey;
  font-size: min(3vw, 24px);
}

.stats-dialog {
  max-width: 800px !important;
}

mat-dialog-actions > button {
  background-color: #404040 !important;
}

.mat-menu-panel {
  background: #121213 !important;
  border: 1px solid white;
}

.top-left-button {
  top: -7px;
}

.give-up {
  border-color: #f44336 !important;
  color: #f44336 !important;
  background-color: transparent !important;
}

.play-again {
  border-color: #4BB543 !important;
  color: #4BB543 !important;
  background-color: transparent !important;
}

.mat-button-disabled {
  border-color: rgba(255,255,255,.3) !important;
  color: rgba(255,255,255,.3) !important;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: white;
}

.clipboard-notification {
  color: white;
  background-color: #121213;
  border: 1px solid white;
  display: flex !important;
  justify-content: center;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: white !important;
}

.mat-form-field.mat-focused  .mat-form-field-ripple {
  background-color: white !important;
}

.mat-input-element {
  caret-color: white;
}

@media (max-width: 728px) {
  .top-left-button {
    position: fixed !important;
    top: calc(var(--vh, 1vh) * 1.5);
    line-height: calc(var(--vh, 1vh) * 3) !important;
    font-size: min(14px, calc(var(--vh, 1vh) * 2.5));
  }
}
